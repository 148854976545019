.block-search {
  @media screen and (max-width: 1199px) {
    clear: both;
  }
  .input-group {
    .form-text, .btn {
      background-color: $gray-lighter;
      border: 0;
    }
    .form-text {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      &:focus {
        box-shadow: none;
      }
    }
    .btn {
      font-size: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      &::after {
        font-family: $font-icon;
        content: $fa-search;
        font-size: 1.55rem;
        color: $brand-primary;
      }
    }
  }
}
