.front {
  #heading, .highlighted {
    background-color: $brand-secondary;
  }
  .main-container {
    padding-top: 2rem;
      }
  h1, .nav-tabs, .action-links {display: none}
  .main-container {
    background-color: $gray-lighter;
  }
  .block-title {
    text-transform: uppercase;
    color: $brand-secondary;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 100;
  }
  footer {
    margin-top: 2rem;
    .block-title {
      text-transform: inherit;
      color: $brand-quaternary;
      text-align: left;
      font-weight: normal;
      font-size: 1.4rem;
    }
  }
  .more-link {
    margin: auto;
    display: block;
    width: 150px;
    line-height: 2.5;
  }

  #block-block-13 {
    .block-covid-19 {
      box-shadow: 0px 0px 10px 3px #c0c0c0;
      padding: 5px 15px 15px 15px;
      margin-top: 15px;
      h2 {
        text-align: center;
      }
      #region-covid {
        .region-covid-inner {
          #block-block-14 {
            .block-inner {
              .content {
                display: flex;
                flex-direction: column;
                align-items: center;
                .covid-region-btn {
                  a {
                    margin: 0px 100px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
