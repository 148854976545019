.share {
  padding: 0;
  li {
    list-style: none;
    text-align: center;
    float: left;
    a {
      padding: 10.5px 0;
      color: $gray-lightest;
      i {
        width: 40px;
        height: 40px;
        font-size: 20px;
      }
    }
  }
  .twitter a {
    background: $twitter;
  }
  .facebook a {
    background: $facebook;
  }
  .linkedin a {
    background: $linkedin;
  }
  .mail a {
    background: $gray;
  }
}
@media only screen and (min-width: 768px) {
  .share {
    margin-top: 60px;
    position: absolute;
    max-width: 40px;
    li {
      float: none;
    }
    &.affix {
      position: fixed;
    }
  }
}
