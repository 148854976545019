.node-type-evenement, .node-type-evenement-congres {
  article {
    position: relative;
  }
  .field-type-datetime, .field-type-location, .field-name-field-type-evenement {
    padding-left: 2rem;
    line-height : 2;
    @media screen and (min-width: 992px) {
      max-width: 50%;
    }
    &::before {
      position: absolute;
      margin-left: -2rem;
      font-family: $font-icon;
      color: $brand-primary;
    }
  }
  // .field-type-datetime, .field-name-field-type-evenement {
  //   display:inline-block;
  // }
  .field-type-datetime {
    &::before {
      content: $fa-calendar;
    }
  }
  .field-name-field-type-evenement {
    // float: right;
    &::before {
      content: $fa-tag;
    }
  }
  .field-type-location {
    &::before {
      content: $fa-map-marker;
    }
    .gmap {
      max-width: 100%;
      margin: auto;
      @media screen and (min-width: 992px) {
        position: absolute;
        right: 0;
        top: 0;
        max-width: 50%;
      }
    }
  }
}
