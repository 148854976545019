// Accordion
.paragraphs-item-accordion {
  .panel-heading {
    background: #ededed;
    border-radius: 0;
  }
  .panel-default {
    border: 0;
    background: transparent;
  }
  .panel-body {
    background: transparent;
  }
  .panel-title {
    width: 100%;
    display: block;
    a {
      display:block;
      width: 100%;
      color: #8f8f8f;
      &:hover {
        text-decoration: underline;
      }
      &::after {
        font-family: 'FontAwesome', sans-serif;
        content: '\f103';
        float: right;
      }
    }
  }
}
