footer {
  background: $brand-secondary;
  color: $gray-lightest;
  margin-top: 3rem;
  padding-top: 1rem;
  .block-title {
    display: block;
    font-size: 1.4rem;
    color: $brand-quaternary;
    text-align: left;
    font-weight: 600;
  }
  .block {
    p {
      font-size: 1.4rem;
          font-weight: 100;
    }
    @media (max-width: $screen-xs-max) {
      margin-bottom: 1.5rem;
    }
  }
  .more-link-footer {
    text-decoration: none;
    color: #06804e;
    float: right;
    font-size: 2rem;
    &:hover {
      color: #f06f05;
    }
  }
  .footer-copyright {
    padding: 10px 0;
    background: $brand-primary;
    color : $gray-lightest;
    font-size: 1rem;
    margin-top: 2.5rem;
    ul {
      padding: 0;
      text-align: center;
      li {
        display: inline;
        &:not(:last-child)::after {
          content:'|';
        }
        a {
          display: inline;
          color : $gray-lightest;
        }
      }
    }
  }
}
