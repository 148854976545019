.page-node-279 .node-webform .webform-client-form .fieldset_wrapper_civi {
  background:rgba(13, 127, 78, 0.1);
  border: 1px solid #e1e1e1;
  .fieldset-wrapper {
    padding-top: 0;
  }
  .form-item {
    width: 100%;
  }
  .webform-container-inline label {
    display: inline-block;
    text-align: right;
    width: 100%;
    @media screen and (min-width: 768px){
      width: 23%;
    }
  }
  select, input {
    max-width: 100%;
    @media screen and (min-width: 768px){
      max-width: 60%;
    }
  }
  .upload-file {
    input {
      &.form-file {
        background: none;
        max-width: 272px;
        margin-right: 5px;
      }
      &.form-submit {
        height: auto;
      }
    }
    .file-icon {
      float: none;
    }
    span.file {
      display: inline-block;
      .file-icon {
        float: inherit;
      }
      a {
        display: inline-block;
        height: 18px;
        max-width: 272px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .form-textarea-wrapper textarea {
    width: 93%;
  }
  input.name, input.first_name {
    @media screen and (min-width: 768px){
      width: 35%;
    }
  }
}

.page-node-279 {
  .node-webform .webform-client-form .fieldset_wrapper_civi {
    .birth_date input {
      margin-left: 2px;
      margin-top: 2px;
      padding: 3px;
    }
    input.form-checkbox {
      margin-right: 5px;
    }
    label.option {
      text-align: left;
      padding-left: 5px;
    }
  }
  .webform-layout-box.equal {
    &.hor_box_adresse_pro > *, &.hor_box_adresse_perso > * {
      width: 100%;
    }
  }



  .node-webform .webform-client-form .fieldset_wrapper_civi {
    label.entete {
      width: 100%;
      text-align: center;
      background-color: rgba(6,47,77,0.7);
      color: $gray-lightest;
      padding: 10px;
      font-size: 15px;
      text-align: center;
      &.entete_first {
        margin-top: 0;
      }
    }
    .webform-datepicker {
     .form-item select {
      display:inline-block;
      max-width: 27%;
      margin-right: 2px;
      @media screen and (min-width: 768px){
        max-width: 122px;
      }
    }
  }
    .sur-specialites {
      button {
        background: $gray-lightest;
        font-size: 1em;
        margin-left: 4px;
        padding: 3px 10px;
        height: 34px;
        max-width: 100%;
      }
      label {
        display: block;
        float: left;
      }
    }
  }
  .ui-state-hover {
    background: #F4F4F4;
  }
}

/* jQuery multi-select plugin */

.ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
  background: #F4F4F4;
}

.page-node-279 {
  .ui-corner-all {
    border-radius: 0px;
  }
  .ui-widget-header {
    background: #F4F4F4;
  }
  .ui-helper-reset li.ui-multiselect-close, .ui-multiselect-checkboxes li {
    background: none;
  }
}

//Page de contribution
.crm-public-form-item {
  #membership {
    #priceset {
      .description {
        display: flex;
        flex-direction: column;
      }
      #pricesetTotal {
        #pricevalue {
          font-weight: bold;
          font-size: 1.7rem;
        }
      }
    }
  }
}