#block-views-devenir-membre-block {
  padding: 3rem 0;
  color: $gray-lightest;
  @media screen and (min-width: 480px) {
    padding: 3rem;
  }
  .paragraphs-item-texte {
    @media screen and (min-width: 991px) {
      display: flex;
      align-items: center;
    }
  }
  .titre {
    @media screen and (max-width: 1199px) {
      text-align: center;
    }
    h2 {
      color: $gray-lightest;
      margin: 0;
      @media screen and (max-width: 479px) {
        font-size: 2.2rem;
      }
      @media screen and (min-width: 480px) {
        font-size: 3.2rem;
      }
    }
    strong {
      @media screen and (max-width: 479px) {
        font-size: 1.8rem;
      }
      @media screen and (min-width: 480px) {
        font-size: 2.4rem;
      }
    }
  }
  li, strong {
    font-weight: 100;
    font-size: 1.5rem;
  }
  li {
    line-height:3;
    list-style: none;
    &::before {
      position: absolute;
      margin-left:-3.5rem;
      margin-top: -2.5rem;
      font-family: $font-icon;
      content: $fa-check;
      color: $brand-primary;
      font-size: 3rem;
      font-weight: 100;
    }
  }
  .bouton {
    @media screen and (max-width: 991px) {
      text-align: center;
    }
    @media screen and (min-width: 1199px) {
      text-align: right;
    }
    .btn {
      width: 150px;
      line-height: 2;
    }
  }
}
