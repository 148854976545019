.page-node-277 {
  .sidebar{
    @media screen and (max-width: 767px){
      clear: both;
    }
  }
  .fieldset_wrapper_civi{
    background:rgba(13, 127, 78, 0.1);
    border: 1px solid #e1e1e1;
    width: 100%;
  }
  .formulaire-titre{
    background-color: $brand-secondary-opacite;
    color: $gray-lightest;
    padding: 10px;
    font-size: 15px;
    text-align: center;
  }
  .titre-info-prof,
  .titre-ad-pro,
  .titre-ad-pers,
  .titre-ad-mail,
  .titre-parrainage,
  .titre-id-site,
  .titre-selec-type-adhesion{
    background-color:rgba(6, 47, 77, 0.7);
    color: #fff;
    padding: 10px;
    font-size: 15px;
    text-align: center;
  }
  .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-civicrm-1-contact-1-contact-suffix-id{
    display: block;
    width: 50%;
  }
  .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-civicrm-1-contact-1-contact-last-name,
  .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-civicrm-1-contact-1-contact-first-name {
    width: 100%;
  }
  .day{
    width: 30%;
    float: left;
    @media screen and (min-width: 768px){
        width: 15%;
        margin-left: 10px;
    }
    @media screen and (min-width: 1200px){
        width: 10%;
    }
  }
  .month{
    width: 30%;
    float: left;
    margin-left: 10px;
    margin-right: 10px;
    @media screen and (min-width: 768px){
      width: 15%;
    }
  }
  .year{
    width: 33%;
    @media screen and (min-width: 768px){
      width: 19.5%;
    }
  }
  .control-label{
    margin-left: 10px;
  }
  .webform-layout-box.equal.child-width-8 > div{
    width: 100%;
    @media screen and (min-width: 768px){
      width: 47%;
    }
    @media screen and (min-width: 1200px){
      width: 48%;
    }

  }
  .webform-component--civicrm-1-contact-1-fieldset-fieldset--info-email {
    padding-left:5px;
    padding-right: 5px;
  }
  .webform-component--civicrm-1-contact-1-fieldset-fieldset--info-adhesion {
    padding-left: 5px;
  }
  #content-wrapper h1{
    font-size: 20px ;
  }
  @media screen and (min-width: 768px){
    .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-civicrm-1-contact-1-contact-prefix-id {
      width: 40%;
      display: inline-block;
    }
    .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-civicrm-1-contact-1-contact-last-name {
      width: 47%;
      float: left;
      margin-left: 10px;
    }
    .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-type-dadhesion {
      width: 20%;
      margin-left: 10px;
    }
    .webform-component--civicrm-1-contact-1-fieldset-fieldset--info-email {
      padding-left: 10px;
      padding-right: 10px;
    }
    .webform-component--civicrm-1-contact-1-fieldset-fieldset--info-adhesion {
      padding-left: 10px;
    }
    .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-hor-box-adresse-pro-civicrm-1-contact-1-address-supplemental-address-1,
    .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-hor-box-adresse-pro-civicrm-1-contact-1-address-country-id,
    .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-hor-box-adresse-perso-civicrm-1-contact-2-address-supplemental-address-1,
    .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-hor-box-adresse-perso-civicrm-1-contact-2-address-country-id{
      width: 47%;
      margin-left: 10px;
    }
    .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-hor-box-adresse-pro-civicrm-1-contact-1-address-street-address,
    .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-hor-box-adresse-perso-civicrm-1-contact-2-address-street-address{
      width: 97.7% ;
      margin-left: 10px;
    }
    .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-hor-box-adresse-pro-civicrm-1-contact-1-address-city,
    .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-hor-box-adresse-perso-civicrm-1-contact-2-address-city {
      width: 60% ;
      margin-left: 10px;
    }
    .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-hor-box-adresse-pro-civicrm-1-contact-1-address-postal-code,
    .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-hor-box-adresse-perso-civicrm-1-contact-2-address-postal-code{
      width: 22% ;
      margin-left: 10px;
    }
    .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-hor-box-adresse-perso-civicrm-1-contact-2-address-state-province-id,
    .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-hor-box-adresse-perso-civicrm-1-contact-2-address-supplemental-address-2,
    .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-hor-box-adresse-pro-civicrm-1-contact-1-address-state-province-id,
    .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-hor-box-adresse-pro-civicrm-1-contact-1-address-supplemental-address-2,
    .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-civicrm-1-contact-1-contact-first-name {
      width: 47% ;
      margin-right: 14px;
      float: right;
    }
    .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-hor-box-adresse-perso-hor-box-tel-secondaire-civicrm-1-contact-2-phone-phone,
    .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-hor-box-adresse-pro-hor-box-tel-principal-civicrm-1-contact-1-phone-phone{
      width: 70%;
      margin-left: 10px;
    }
    .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-mot-de-passe,
    .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-confirmer-le-mot-de-passe,
    .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-civicrm-1-contact-1-cg1-custom-3,
    .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-civicrm-1-contact-2-email-email,
    .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-civicrm-1-contact-1-email-email {
      width: 50%;
      margin-left: 10px;
    }
  }
}
