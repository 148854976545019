#block-user-login,
.highlighted-block {
  overflow: hidden;
  padding: 25px;
  border-radius: 6px;
}
.highlighted-block-inside {
  padding: 0 25px 25px;
  text-align: center;
}
.highlighted-block {
  .btn {
    background-color: $gray-dark;
    color: $gray-lightest;
    display: block;
    padding: 15px 25px;
    margin: 20px 0 0;
    text-align: center;
    border-radius: $btn-border-radius-base;
  }
  &.nopadding {
    padding: 0;
  }
  .title-sep {
    margin: 0 0 40px;
  }
}

#block-user-login {
  padding: 5px 25px 0;
  margin: 0 0 20px;
}
