body {
  font-family: $font-family-sans-serif;
}
p {
  line-height: 1.5;
}
.submitted {
  display:block;
  clear: both;
  color: $gray-light;
  font-size: 1.2rem;
  padding: 0;
  font-weight: 100;
  &::before {
    font-family: $font-icon;
  }
}
.node-type-article, .view-actualit-s {
  .submitted {
    &::before {
      content: $fa-calendar-o;
    }
  }
}
.node-type-page {
  .submitted {
    &::before {
      content: $fa-calendar-o;
    }
  }
}
// Headings
h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}
h1 {
  color: $brand-primary;
  font-size: 2.6rem;
}
h2 {
  color: $brand-secondary;
  font-size: 2.2rem;
}
h3 {
  color: $brand-tertiary;
  font-size: 1.8rem;
}
h4 {
  color: $brand-primary;
  font-size: 1.6rem;
}
h5 {
  color: $brand-secondary;
  font-size: 1.4rem;
}
h6 {
  color: $brand-tertiary;
  font-size: 1.4rem;
}
.page-header {
  margin: 40px 0 5px;
  border: none;
}
// Boutons
.btn {
  border: 0;
}
.btn-primary a {
  color: $gray-lightest;
  &:hover {
    text-decoration: none;
    background: $brand-tertiary;
  }
}
.btn-primary {
  @include button-variant($gray-lightest, $brand-primary, $brand-primary);
  &:hover {
    border: $brand-tertiary;
    background: $brand-tertiary;
  }
}
