.region-header-top-right {
    position: relative;
    z-index: 6;
    right: 0;
    width: 100%;
}

#block-system-user-menu {
  text-align: right;
  position: absolute;
  z-index: 6;
  right: 0;
  @media screen and (max-width: 991px) {
    margin-right: 26px;
  }
  li {
    display: inline-block;
    text-align: center;
    font-size: 1.1rem;
    &:nth-child(1) {
      > a {
        @media screen and (max-width: 1199px) {
          position: relative;
          top: -6px;
          padding: 10px 4px;
        }
        &::before {
          display:block;
          font-family: $font-icon;
          content: $fa-user-circle;
          font-size: 3rem;
          color: $brand-primary;
        }
      }
      &:hover a::before {
        color: $brand-tertiary;
      }
    }
    &:nth-child(2) {
      a {
        background: $brand-primary;
        color: $gray-lightest;
        text-transform: uppercase;
        font-size: 1.4rem;
        font-weight: 700;
        position: relative;
        @media screen and (max-width: 1199px) {
          padding: 9px 16px;
          top: -30px;
        }
        @media screen and (min-width: 1200px) {
          padding: 24px 28px;
          top: -24px;
        }
        &:hover {
          background: $brand-tertiary;
        }
      }
    }
  }
}
.logged-in {
  #block-system-user-menu {
    li:nth-child(3) {
      display: block;
      position: absolute;
      border: 1px solid $brand-primary;
      right: 0;
      &:hover {
        border: 1px solid $brand-tertiary;
      }
      @media screen and (max-width: 1199px) {
        top: 53px;
      }
      @media screen and (max-width: 479px) {
        > a {
          padding: 4px 7px;
        }
      }
      @media screen and (min-width: 480px) and (max-width: 991px) {
        position: relative;
        top:-32px;
        display: inline-block;
      }
      @media screen and (min-width: 480px) and (max-width: 1199px) {
        > a {
          padding: 10px 11px;
        }
      }
      @media screen and (min-width: 1200px) {
        top: 73px;
        > a {
          padding: 10px 19px;
        }
      }
    }
  }
}
