#banner .container {
  @media screen and (max-width: 991px) {
  max-width: 100%;
  }
  @media screen and (min-width: 992px) {
      width: 970px;
    }
}

#block-views-home-congr-s-block {
  .block-title {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}
.view-home-congr-s {
  .view-content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    max-width: 1020px;
    margin: 3rem auto 6rem auto;
    @media screen and (max-width: 991px) {
      align-items: stretch;
    }
  }

  .tile {
    color: $gray-lightest;
    border: 1px solid #eee;
    font-size: 1.6rem;
    padding: 0;
    img {
      height: auto;
      @media screen and (max-width: 991px) {
        margin: auto;
      }
      @media screen and (min-width: 992px) {
        margin-right: 0;
      }
    }
    &:nth-child(1) {
      background: $brand-primary;
    }
    &:nth-child(2) {
      background: $brand-tertiary;
      }
    &:nth-child(3) {
      background: $brand-secondary;
    }
    &:nth-child(4) {
      background: $brand-quaternary;
    }
    &:nth-child(even) {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
    }
    .texte {
      padding: 2rem 2rem 0 2rem;
    }
    a {
      color: $gray-lightest;
    }
    .date {
      padding-bottom: 1rem;
      font-size: 1.3rem;
      font-weight: 100;
    }
    ul {
      padding-left: 2rem;
      li {
        list-style: none;
        line-height: 2;
        font-weight: 100;
        &::before {
          position: absolute;
          margin-left: -2rem;
          font-family: $font-icon;
        }
        &:nth-child(1)::before {
          content: $fa-clock-o;
        }
        &:nth-child(2)::before {
          content: $fa-pencil;
        }
        &:nth-child(3)::before {
          content: $fa-map-marker;
        }
        &:nth-child(4)::before {
          content: $fa-youtube-play;
        }
      }
    }
  }
}
