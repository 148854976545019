.paragraphs-item-toc {
  clear: both;
  list-style-type: none;
  position: relative;
  ul {
    list-style-type: none;
    padding-left: 1rem;
  }
  .panel-heading, .panel-body {
    background-color: $gray-lighter;
    border-top: 0;
  }
  .panel-heading a,
    &::before {
    color: $gray-lightest;
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 1.1;
    text-shadow: 1px 1px 2px $gray;
    background: transparent;
  }
  .panel-heading a {
    display: block;
    margin-left: .5rem;
    width: 100%;
    z-index: 2;
    position: relative;
    &:hover, &:active, &:focus {
      text-decoration: none;
    }
    &::before {
      position: relative;
      left: -.5rem;
      font-family: $font-icon;
      content: $fa-sitemap;
    }
  }
  .panel-title::after {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    font-family: 'FontAwesome', sans-serif;
    content: '\f103';
    float: right;
    color: $gray-lightest;
    text-shadow: 1px 1px 2px $gray;
    }
  a {
    color: $brand-primary;
    }
  li {
    padding-left: 1.5rem;
    &::before {
      position: absolute;
      margin-left: -1.5rem;
      margin-top: -.7rem;
      content: "• ";
      color: $brand-secondary;
      font-size: 2.4rem;
    }
    ul {
      font-size: 1.2rem;
      li::before {
        font-family: $font-icon;
        content: $fa-caret-right;
        color: $brand-tertiary;
        margin-top: 0;
        font-size: 1.4rem;
     }
    }
  }
}
