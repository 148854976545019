#heading {
  overflow: hidden;
  @media screen and (max-width: 479px) {
    max-height: 654px;
  }
  @media screen and (min-width: 480px) and (max-width: 767px) {
    max-height: 481px;
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    max-height: 372px;
  }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    max-height: 365px;
  }
  @media screen and (min-width: 1200px) {
    max-height: 370px;
  }
}

#block-views-slider-block{
  .view-slider {
    background-color: $brand-secondary;
    color: #ffffff;
    padding: 2.5rem 0;
    @media screen and (min-width: 480px) and (max-width: 767px) {
      padding-top: 3rem;
    }
    @media screen and (min-width: 768px) {
      padding-top: 3rem;
    }
    @media screen and (min-width: 1200px) {
      height: 370px;
    }
    @media screen and (max-width: 479px) {
      .text-slider {
        width: 100%;
      }
    }
  }
  .slider-home {
    padding: 0;
  }
  .bx-viewport {
     z-index: 1;
  }
  // Image
  .img-responsive {
    @media screen and (max-width: 479px) {
      width: 100%;
    }
    @media screen and (min-width: 480px) and (max-width: 767px) {
      width: 50%;
      max-width:320px;
      position: absolute;
      left: 0;
    }
    @media screen and (min-width: 1200px){
      position: absolute;
      left: 175px;
      top: 18px;
      height: 277px;
      width: 366px;
    }
  }
  @media screen and (min-width: 1200px){
    .tablette-background{
      background-image: url("../img/ipad-background.png");
      width: 663px;
      float: left;
      height: 340px;
    }
    .views-field-title{
      margin-top: 2rem;
    }
  }
  @media screen and (min-width: 1200px){
    .views-field-field-image{
        margin-left: -2rem;
    }
  }
// Text
  .title a {
    color: $brand-quaternary;
    font-weight: bold;
    font-size: 2.5rem;
    display: block;
  }
  strong {
    color: $brand-quaternary;
    font-weight: 100;
    font-size: 2rem;
  }
  p {
    font-size: 1.6rem;
    margin: 0;
  }
  .text-slider {
    margin-top: 8px;
    .btn {
      width: 150px;
      line-height: 2;
      margin-top: 1.5rem;
    }
    @media screen and (min-width: 479px) {
      width: 40%;
    }
    @media screen and (min-width: 480px) and (max-width: 767px) {
      width: 50%;
      float: right;
    }
    @media screen and (min-width: 1200px) {
      width: 41%;
      padding-top: 30px;
    }
  }

  // Pager
  .bx-default-pager{
    text-align: center;
    @media screen and (min-width: 1200px){
      position: relative;
      bottom: 51px;
      left:0;
      right: 0;
      z-index: 2;
    }
  }
  .bx-pager-item{
    display: inline-block;
  }
  .bx-pager-link{
    font-size: 0;
    width: 18px;
    height: 18px;
    background-color: $gray-lightest;
    border-radius: 100%;
    display: block;
    margin-right: 1rem;
    &.active {
      background-color: $brand-quaternary;
    }
  }
}
