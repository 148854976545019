
#header {
  position: relative;
  z-index: 5;
  padding: 20px 0;
  text-align: center;
}
.branding {
  padding: 0;
  z-index: 1;
  @media screen and (max-width: 991px) {
      top: -17px;
  }
}
.logo {
  padding: 0;
  text-align: center;
  display: inline-block;
  img {
    position: relative;
    z-index: 1;
    @media screen and (max-width: 479px) {
      width:125px;
    }
    @media screen and (min-width: 480px) and (max-width: 1199px) {
      width:150px;
    }
    @media screen and (min-width: 1200px) {
      width:234px;
    }
    &:hover {
      opacity: .8;
      filter: alpha(opacity = 80);
    }
  }
}
#site-name {
  clear: both;
  margin-top: .75rem;
  margin-bottom: 0;
  @media screen and (max-width: 479px) {
    display:none;
  }
  @media screen and (min-width: 480px) {
    display:block;
    font-size: .6rem;
  }
  @media screen and (min-width: 1200px) {
    font-size: 1rem;
  }

  a {
    text-align: center;
    font-weight: normal;
    line-height: 1em;
    margin: 0 0 10px;
    text-transform: uppercase;
    color: $brand-primary;
    letter-spacing: 0.9px;
    .first-letter {
      font-size: 1.4rem;
      @media screen and (min-width: 1200px) {
        font-size: 1.8rem;
      }
    }
    &:hover {
      text-decoration: none;
      opacity: .8;
      filter: alpha(opacity = 80);
    }
  }
}
