.view-actualit-s {
    background-color: $gray-lighter;
    padding: 25px;

  .page-header {
    text-align: center;
    font-size: 25px;
  }
  .views-row {
    margin-bottom: 30px;
    padding: 0;
    background-color:$gray-lightest;
    @media screen and (min-width: 768px) {
      width: 48%;
    }
  }
  .field-content {
    a {
      background-color:$brand-quaternary;
      color: #000;
      &:hover {
        text-decoration: none;
      }
    }

  }
  .views-field-title h2 {
    font-size: 15px;
    color: $brand-quaternary;
  }
  .view-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .btn {
      bottom: 15px;
      bottom: 15px;
      left: 30px;
      position: absolute;
      &:hover {
        border: $brand-primary;
        background: $brand-primary;
      }
    }
  }
  .animation-actualites {
    display: block;
    padding: 25px;
    height: 290px;
    @media screen and (min-width: 1200px) {
      height: 230px;
    }
  }
  .text-actualites-anim p {
    position: relative;
  }
  .title-actualites-anim {
    font-size: 15px;
    font-weight: bold;
    color: $brand-primary;
    position: relative;
    display: block;
    @media screen and (min-width: 768px){
      font-size: 15px;
    }
  }
  span {
    position: relative;
    z-index: 1;
  }
  .submitted {
    margin: 0;
    padding: .25rem 0;
    font-size: 1.2rem;
    position: relative;
    z-index: 1;
    &::before {
      margin-right: .5rem;
    }
  }
  .animation-actualites:hover {
    .btn {
        border: $brand-tertiary;
        background: $brand-tertiary;
    }
    &::before{
      @media screen and (min-width: 1200px) {
        content: '';
        background-color: $gray-lightest;
        height: 250px;
        width: 398px;
        position: absolute;
        top: -5px;
        left: -7.5px;
      }
    }
  }
  .animation-actualites-sfn {
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
  }
    }
