.profile .view .view-header h3{
    font-size: 24px;
}

.profile .view .view-content .btn{
    line-height: 25px;
}

.profile .view .view-content .field-content {
    display: flex;
    flex-direction: column;
    .link-contribution {
        margin: 0 auto;
    }
    .address {
        display: flex;
        justify-content: center;
        text-align: center;
    }
    .btn-primary:hover{
        transition: 0.3s;
    }
}