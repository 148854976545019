#block-views-partenaires-block {
  background: $gray-lightest;
  padding: 1.5rem 1.5rem 0 1.5rem;
}
.view-partenaires {
    max-width:1140px;
    margin: auto auto 10px auto;
    .view-content {
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 100px;
    }
    .logo-partenaire {
      display: inline-block;
      @media only screen and (min-width: $screen-sm-min) {
        margin-right: 5.5%;
      }
      img {
        width: 80px;
        &:hover {
          filter: grayscale(100%);
        }
      }
    }
}
