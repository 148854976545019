// Drupal Message status

.messages {
  padding: 15px 15px 15px 50px;
  margin-bottom: 20px;
  a {
    font-weight: bold;
  }
  &::before {
    font-family: $font-icon;
    position: absolute;
    margin-left: -35px;
    margin-top: -5px;
    font-size: 2.5rem;
  }
  &.status {
    color: $brand-success;
    background-color: $brand-success-bg;
    border-color: $brand-success-border;
    a {
      color: $brand-success-link;
    }
    &::before {
      content: "\f058";
    }
  }
  &.error {
    color: $brand-danger;
    background-color: $brand-danger-bg;
    border-color: $brand-danger-border;
    a {
      color: $brand-danger-link;
    }
    &::before {
      content: "\f057";
    }
  }
  &.warning {
    color: $brand-warning;
    background-color: $brand-warning-bg;
    border-color: $brand-warning-border;
    a {
      color: $brand-warning-link;
    }
    &::before {
      content: "\f071";
    }
  }
}

.alert-dismissable, .alert-dismissible {
  .close {
    top: -1rem;
    right: 0;
    font-size: 3rem;
    float: right;
    &:hover, &:active, &:focus {
      text-decoration: none;
      opacity: .7;
    }
  }
}
