// Alert
.paragraphs-item-alerte {
  display: inline-block;

  width: 100%;
  .icon {
    float: left;

    margin-right: 10px;
  }
  p {
    text-align: left;
  }
}

// Image caption
figure {
  display: table;
  margin: auto auto 10px;
  &.pull-left {
    margin-right: 20px;
  }
  &.pull-right {
    margin-left: 20px;
  }
  figcaption {
    display: table-caption;
    max-width: 100%;
    margin-top: 5px;
    caption-side: bottom;
    color: $gray-light;
    font-size: 12px;
    font-style: italic;
    line-height: 18px;
  }
}

// Image mosaique
.paragraphs-item-images-mosaique {
  .container-img {
    display: flex;
    align-items: center;
    // justify-content: center;
    flex-wrap: wrap;
  }
  h3 {
    display: block;
    clear: both;
    padding: 15px 0;
  }
  .caption {
    color: $gray-light;
    font-size: 12px;
    font-style: italic;
    line-height: 18px;
  }
}

// Notification
.paragraphs-item-notification {
  padding: 20px;

  border-width: 1px;
  border-style: solid;
  .icon {
    float: left;

    margin-right: 20px;
  }
}

