.background-breadcrumb {
  background-image: url("../img/banniere.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  color: $gray-lightest;
  background-position-y: -20px;
  a {
    color: $gray-lightest;
    text-decoration: none;
  }

}
.background-transparent{
  @include background-opacity($gray-base, $opacity: 0.7)
  z-index: 10;
  padding-top: 75px;
  padding-bottom: 75px;
}
.rubrique {
  font-size: 3.2rem;
  font-weight: 600;
}
.breadcrumb {
  background-color: transparent;
  color: $gray-lightest;
  padding: 0;
  > span {
    margin: 0 2px 0 0;
    display: inline;
    font-size: 1.4rem;
  }
}
