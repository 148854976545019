.page-node-278{
  .sidebar{
    @media screen and (max-width: 767px){
      clear: both;
    }
  }
  .titre-contact-sfn{
    font-size: 18px;
    color:$brand-secondary-opacite ;
    @media screen and (min-width: 768px){
      font-size: 20px;
    }
  }
  .mail-contact-sfn {
    a {
      color: $brand-quaternary;
    }
  }
  #webform-client-form-278{
    margin-top: 20px;
    @media screen and (min-width: 768px){
      width: 50%;
      float: left;
      padding: 10px
    }
  }
  @media screen and (min-width: 768px){
    .txt-contact-sfn{
      width: 50%;
      float: left;
    }
  }
}