//==  Colors
$brand-secondary: #062f4d;
$brand-secondary-opacite: rgba(6, 47, 77, 0.7);
$brand-tertiary: #f06f05;
$brand-quaternary: #0ea365;
$brand-quinary:rgb(11, 104, 173);
$brand-success-bg: #dff0d8;
$brand-success-border: #d6e9c6;
$brand-success-link: #356635;
$brand-warning-bg: #fcf8e3;
$brand-warning-border: #faebcc;
$brand-warning-link: #a47e3c;
$brand-danger-bg: #f2dede;
$brand-danger-border: #ebccd1;
$brand-danger-link: #953b39;
$gray-border: #ccc;

// Réseaux sociaux
$facebook: #3B5998;
$linkedin: #007BB6;
$twitter: #00ACED;

// Background transparent
@mixin background-opacity($color, $opacity: 0.3) {
    background: $color;
    background: rgba($color, $opacity);
}

//## Gray and brand colors for use across Bootstrap.
// https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_variables.scss

$gray-base: #000;
$gray: #474747;
$gray-lighter: #ebebeb;
$gray-lightest: #ffffff;
$brand-primary: #06804e;

$link-color: $gray;
$link-hover-color: $brand-primary;

//== Navbar
$navbar-border-radius: 0;
$nav-link-hover-bg: transparent;
$grid-float-breakpoint: 992px;
//== Btn
$border-radius-base: 0;
$border-radius-large: 0;
$border-radius-small: 0;
$btn-border-radius-base: 3px;
$btn-border-radius-large: 3px;
$btn-border-radius-small: 3px;
//== Forms
$input-border-radius:0;
$input-border-focus: $brand-primary;
//== Thumbnails
$thumbnail-border: $gray-lightest;
$thumbnail-border-radius: 0;
//== Pagination
$pagination-active-bg: $brand-primary;
$pagination-active-border: $brand-primary;
$pagination-color: $brand-primary;
$pagination-hover-color: $brand-primary;

//== Forms
$input-color-placeholder: $gray-base;
