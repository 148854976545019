#toTop {
  position: fixed;
  border-radius: 3px;
  background: $gray-light;
  opacity: .4;
  filter: alpha(opacity = 40);
  width: 55px;
  height: 55px;
  bottom: 5px;
  right: 5px;
  cursor: pointer;
  color: $gray-lightest;
  font-size: 32px;
  text-align: center;
  display: none;
  padding: 5px 0 0 2px;
  z-index: 14;
  &:hover {
    opacity: 1;
    filter: alpha(opacity = 100);
    transition: all .3s;
  }
}
