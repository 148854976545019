#main-navigation {
  padding: 0;
}

.navbar-toggle {
  top: -12px;
  background: $brand-primary;
  border: none;
  text-shadow: none;
  color: $brand-primary;
  width: 36px;
  height: 36px;
  text-align: center;
  z-index: 100;
  margin: 0;
  &.affix, &.affix:active, &.affix:focus {
    position: fixed;
    top: 50px;
    margin-left: 88%;
    @include background-opacity($gray-darker, $opacity: .3)
    i {
      padding-top: .7rem;      }
    }
  .menu {
    display: none;
  }
  i {
    &::before {
      color: $gray-lightest;
      font-size: 2rem;
    }
  }
  &.active,
  &.disabled,
  &:active,
  &:focus,
  &:hover,
  &[disabled] {
    color: $gray-lightest;
    background-color: $brand-primary;
  }
  &.active,
  &:active {
    background-color: #bfbfbf \9;
  }
}


#main-navigation-inside {
  @media only screen and (min-width: $screen-md-min) {
    max-width: 670px;
  }
  .navbar-collapse {
    z-index: 1;
    background: $gray-lightest;
    padding: 0;
    @media only screen and (max-width: $screen-sm-max) {
      width:100%;
      position: relative;
      float:right
    }
    @media only screen and (max-width: $screen-sm-max) {
      box-shadow: 0 6px 12px rgba(0,0,0,0.175);
    }
  }

// Liens du menu

  .nav {
    > li {
      a {
        text-transform: uppercase;
        padding: 10px 5px;
        z-index: 1;
      }
      &.active > a {
        background: $brand-primary;
        color: $gray-lightest;
      }
    }
    > li {
      @media only screen and (min-width: $screen-md-min) {
        display:inline-block;
        margin-right: 1px;
        color: $gray-darker;
        > a {
          &:focus,
          &:hover,
          &:active {
            background: $brand-primary;
            color: $gray-lightest;
          }
        }
      }
      &:nth-child(1) > a {
        color: $gray-base;
        background: $gray-lightest;
        font-size: 0;
        padding: 10px 10px 0;
        &:hover {
          &::before {
            color: $brand-secondary;
          }
        }
        &::before {
          color: $brand-primary;
          font-size: 2.4rem;
          font-family: $font-icon;
          content: $fa-home;
          position: relative;
        }
      }
    }
  }
  .dropdown-menu {
    padding: 0;
    margin: 0;
    background: $brand-primary;
    border:0;
    min-width: 300px;
    @media only screen and (max-width: $screen-md-max) {
      position: relative;
      width: 100%;
      margin-bottom: 10px;
      box-shadow: none;
      border:0;
      padding-left: 15px;
      .caret {
        display: none;
      }
    }
    li {
      a {
        padding: 10px;
        font-weight: 400;
        font-size: 1.4rem;
        color: $gray-lightest;
        &:hover {
            color: $gray-darker;
        }
        @media only screen and (min-width: $screen-lg-min) {
          border-top: none;
          line-height: 150%;
        }
      }
      &:last-child a {
        border: 0;
      }
    }
    > .active > a {
      background: $brand-secondary;
      color: $gray-lightest;
      &:hover {
        color: $gray-lightest;
      }
    }
    // Niveau 3
    .dropdown-menu {
      @media only screen and (min-width: $screen-lg-min) {
        transform: translate(290px, -41px);
      }
    }
  }

  @media only screen and (min-width: $screen-lg-min) {
    // Affichage du menu au survol
    .dropdown:hover
    > .dropdown-menu {
        display: block;
        margin-top: 0;
      }
   }
}

// Affix Main menu
.affix {
  position: fixed;
  z-index: 13;
  left: 0;
  right: 0;
  margin: auto;
  background: $brand-primary;
  margin-top: -47px;
  box-shadow: none;
  padding: 0;
  &.navbar-collapse {
    top: 0;
    margin: auto;
    width: 100%;
  }
  &.search-button {
    top: 0;
  }
  &.block-search {
    top: 0;
    max-width: 365px;
  }
  &.share {
    top: 40px;
  }
}
