.view-calendar {
  font-size: 1.4rem;
  .date-evenement {
    float: left;
    text-align: center;
    margin-right: 15px;
    background: url("../img/agenda.png") no-repeat;
    background-size: cover;
    height: 60px;
    width: 55px;
    margin-bottom: 60px;
  }
  .date-jour {
    padding-top: 23px;
    font-weight: bold;
    font-size: 2.2rem;
  }
  .date-mois {
    padding-top: 8px;
  }
  .views-field-city {
    float: left;
    display: inline-block;
    margin-right: 10px;
  }
  .views-field-title a {
    color: $gray-base;
    font-weight: bold;
  }
  .views-field-field-date {
    padding-left: 70px;
  }
  .views-field-field-type-evenement {
    padding-left: 50px;
    color: $brand-tertiary;
    &.Réunion {
      color: $brand-primary;
    }
    .field-content::before {
      font-family: $font-icon;
      content: $fa-tag;
      margin-right: .5rem;
    }

  }
}

// Page Agenda

.view-id-calendar_list {
  .view-grouping-header {
    text-align: center;
    font-size: 3rem;
    color: $gray-lighter;
     clear: both;
  }
  .view-grouping-content  {
    .mois {
      background: $gray-lighter;
      margin-bottom: 10px;
      margin-top: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
      .date-display-single {
        text-align: center;
        font-size: 2rem;
      }
    }
  }
  .views-row {
    min-height: 100px;
    clear: both;
  }
}

// block agenda
#block-views-calendar-list-home-agenda{
  margin-bottom: 3rem;
  .block-title {
    padding-bottom: 3rem;
  }
  .views-row {
    margin-bottom: 10px;
    padding-bottom:10px;
  }
  .date-evenement {
    height: 40px;
    width: 37px;
  }
  .date-jour {
    padding-top: 8px;
    span{
      font-size: 1.7rem;
    }
  }
  .date-mois {
    padding-top: 3px;
    span{
      font-size: 1.5rem;
    }
  }
}

.not-front {
  #block-views-calendar-list-home-agenda{
    .block-title {
      padding-top: 2rem;
    }
  }

}
