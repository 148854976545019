// Revue radiologique

#block-block-11 {
  img {
    float: left;
    height: 118px;
    width: auto;
    margin-right: 1.5rem;
  }
}

// Adresse SFN
#block-block-12 {
  a {
    color: $gray-lightest;
  }
}
